module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Intelligence for Climate Adaptation","short_name":"Intelligence for Climate Adaptation","start_url":"/","background_color":"#FFFFFF","theme_color":"#309975","display":"minimal-ui","icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a4682680b60badbfc4660d2a13756817"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","preconnect":["https://use.typekit.net"],"web":[{"name":"adelle","file":"https://use.typekit.net/jjn3cdo.css"},{"name":"din-2014","file":"https://use.typekit.net/jjn3cdo.css"}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
